import { useRouter } from "next/router";
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import * as yup from 'yup';


import { yupValidator } from "./../../lib/yup-validator";
import { sendOtpForLogin } from "./../../services/user.service";

import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import Input from '../../atoms/Input';
import Modal from '../../atoms/Modal';
import Paragraph from '../../atoms/Paragraph';
import Alert from '../../molecules/Alert';
import { LOGIN_USER_TYPE } from "constant/login_signup";



const ModalLogin = ({ className, isModalOpen, isExistingUser, handleModalStateChange, setIsUserDeactivated, otpScreenForLogin, setMobileNo, setEmail, setIsLoginType, setIsForgatModal, handlerModalClose, openLoginWithPassword, setIsExistingUser, userData, activeTab }) => {
    const [otpValue, setOtpValue] = useState("");
    const [alertMsg, setAlertMsg] = useState(null);
    const [isLoginWithPassword, setIsLoginWithPassword] = useState(false)
    const [isShowPassword, setIsShowPassword] = useState(false)

    const router = useRouter();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupValidator(
            yup.object().shape({
                mobile_Email: yup.string()
                    .min(10, "Enter your 10 digit mobile number")
                    .test("isNumericString", "Mobile can only have Numbers.", async (value) => /^[0-9]{1,10}$/.test(value))
                    .required("Mobile Number is Required"),
            })
        )
    })
    const onSubmit = async (data) => {
        let mobilePattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/
        if (data?.mobile_Email.match(mobilePattern)) {

            const response = await sendOtpForLogin({
                mobileNumber: data?.mobile_Email,
                country_code: "91",
                ...(LOGIN_USER_TYPE[activeTab] && {userType: LOGIN_USER_TYPE[activeTab]}) // PRACTITIONER: 1
            })
            if (response?.data?.message.code === 111 && response?.data?.message.error === 'Your account has been deactivated') {
                setIsUserDeactivated(true);
                handleModalStateChange(false)
                return
            }
            if (response?.data?.message === "Successfully sent Otp!") {
                setMobileNo(response?.data?.entity?.mobileNumber)
                setEmail(response?.data?.entity?.email)
                setIsLoginType('mobile')
                setIsUserDeactivated(false);
                otpScreenForLogin(true);
            } else {
                setAlertMsg({ type: "error", message: response?.data?.message })
            }

        }
        else {
            if (data?.mobile_Email) {
                setAlertMsg({ type: "error", message: "Invalid mobile" });
            }
        }
    }

    useEffect(() => {
        if (userData && isExistingUser) {
            let data = {
                mobile_Email: userData?.mobileNo?.toString()
            }
            onSubmit(data)
        }
    }, [userData, isExistingUser]);

    return (
        <Modal
            type="withoutCloseIcon3"
            className={`${className} max-w-400px xs:max-w-460px sm:max-w-500px lg:max-w-612px w-full`}
            handlerModalClose={handlerModalClose}
            isModalOpen={isModalOpen}
            isShowCloseBtn={true}
            dataAutomation='login-welcome'
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                {alertMsg?.message && <Alert type={alertMsg.type} effect='slideDown' resetAlertCallback={() => setAlertMsg(null)}>{alertMsg.message}</Alert>}
                <Heading type="h3" className='text-center' >Welcome to NirogStreet</Heading>

                <div className="mt-5 xs:mt-6 sm:mt-7 lg:mt-8  mb-9 xs:mb10 sm:mb-11 lg:mb-12 mx-auto">

                    <Paragraph className="text-center mb-8 lg:mb-12">Enter your mobile number you used to register on NirogStreet</Paragraph>

                    <Input
                        type="number"
                        // placeholder='Enter e-mail/mobile number'
                        placeholder='Enter mobile number'
                        register={register}
                        id="mobile_Email"
                        error={errors.mobile_Email}
                        isRequiredField={true}
                        maxLength={10}
                        setValue={setValue}
                    />

                </div>

                <div className="flex flex-col items-center">
                    <Button
                        className="w-200px"
                        size="medium"
                        variant="Primary"
                        dataAutomation={'get-otp'}
                    >
                        {'Get OTP'}
                    </Button>

                </div>
            </form>

        </Modal>
    )
}

ModalLogin.propTypes = {
    className: PropTypes.string,
    isModalOpen: PropTypes.bool,
    handleModalStateChange: PropTypes.func,
}

export default ModalLogin

