import React, { useEffect, useRef, useState } from "react";
import Tab from "atoms/Tab";

const TabsSlide = (props) => {
    const { className = '', tabsClass = '', children, defaultActive = 0 } = props;
    const [tabHeader, setTabHeader] = useState([]);
    const [childContent, setChildContent] = useState({});
    const [childProps, setChildProp] = useState([]);
    const [active, setActive] = useState(defaultActive);
    const tabsRef = useRef(null)

    useEffect(() => {
        const headers = [];
        const childComponent = {};
        const props = {};
        React.Children.forEach(children, (element) => {
            if (!React.isValidElement(element)) return;
            const { name } = element.props;
            headers.push(name);
            childComponent[name] = element.props.children;
            const { children, ...rest } = element.props;
            props[name] = rest;
        });
        setTabHeader(headers);
        setChildProp(props)
        !active.length && setActive(headers[defaultActive]);
        setChildContent({ ...childComponent });
    }, [props, children]);


    const changeTab = (name, e) => {
        setActive(name);
    };



    return (
        <div className={`${className} bg-basic-white md:rounded-2xl`} ref={tabsRef}>
            <ul className={`${tabsClass} flex overflow-x-auto  h-[48px] rounded-3xl border-gray-300 border bg-basic-white text-14-18 md:text-16-24 leading-4 md:leading-5 font-semibold `}>
                {tabHeader.map((item, index) => (
                    <li key={item} onClick={(e) => {
                        !childProps[item]?.disabled && changeTab(item, e)
                        children[index]?.props?.onClick && children[index]?.props?.onClick()
                    }}
                        className={`${item === active ? 'activeTab text-basic-white bg-primary1-500' : 'text-gray-400'} ${childProps[item]?.disabled ? 'cursor-not-allowed' : 'cursor-pointer'} defaultTab  select-none relative z-1 transition-all text-center   px-[20px] xxs:px-[25px] md:px-[30px] flex items-center rounded-3xl`} type='overline' >{item}</li>
                ))}
            </ul>
            <div className="md:pt-3">
                {Object.keys(childContent).map((key) => {
                    if (key === active) {
                        return <div className="tab-child" key={key}>{childContent[key]}</div>;
                    } else {
                        return null;
                    }
                })}
            </div>
        </div>
    );
};

TabsSlide.propTypes = {
    children: function (props, propName, componentName) {
        const prop = props[propName];

        let error = null;
        React.Children.forEach(prop, function (child) {
            if (child.type !== Tab) {
                error = new Error(
                    "`" + componentName + "` children should be of type `Tab.js`."
                );
            }
        });
        return error;
    }
};

export default TabsSlide;