import { trackEvent } from "services/moengage.service";
import login_signup_moengage from "../../assets/mo_engage/login_signup.json";

const events = login_signup_moengage.entity;

export const moEngageLoginPageGetOTP = (attributes = {}) => {
    trackEvent(events.Login_Page_Get_OTP.event_name, attributes)
}

export const moEngageLoginPageSubmitOTP = (attributes = {}) => {
    trackEvent(events.Login_Page_Submit_OTP.event_name, attributes)
}


export const moEngageLoginPageResendOTP = (attributes = {}) => {
    trackEvent(events.Login_Page_Resend_OTP.event_name, attributes)
}


export const moEngageLoginPageChangeNumber = (attributes = {}) => {
    trackEvent(events.Login_Page_Change_Number.event_name, attributes)
}

export const moEngageLoginPageLoginUsingOTP = (attributes = {}) => {
    trackEvent(events.Login_Page_Login_Using_OTP.event_name, attributes)
}

export const moEngageLoginPageLoginUsingPassword = (attributes = {}) => {
    trackEvent(events.Login_Page_Login_Using_Password.event_name, attributes)
}

export const moEngageLoginPageSubmitPassword = (attributes = {}) => {
    trackEvent(events.Login_Page_Submit_Password.event_name, attributes)
}

export const moEngageLoginPageSignUpClick = (attributes = {}) => {
    trackEvent(events.Login_Page_Sign_Up_Click.event_name, attributes)
}

export const moEngageAccountRegistrationSubmitGetOTP = (attributes = {}) => {
    trackEvent(events.Account_Registration_Get_OTP.event_name, attributes)
}

export const moEngageSignupPageLoginClick = (attributes = {}) => {
    trackEvent(events.Signup_Page_Login_Click.event_name, attributes)
}

