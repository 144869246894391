import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { useForm } from "react-hook-form";
import * as yup from 'yup';

import { yupValidator } from "../../lib/yup-validator";
import { changePasswordEmail, changePasswordMobile, sendOtp } from "../../services/user.service"

import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import Modal from '../../atoms/Modal';
import Paragraph from '../../atoms/Paragraph';
import Input from '../../atoms/Input';
import Alert from 'molecules/Alert';


const ModalChangePassword = ({ className, isModalOpen, handleModalStateChange, handleHideOTPRedirect, email, mobile }) => {
    const [alertMsg, setAlertMsg] = useState(null)
    const [isShowPassword, setIsShowPassword] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        // form
    } = useForm({
        resolver: yupValidator(
            yup.object().shape({
                password: yup.string().min(6, "Password should be at least 6 characters").required("Password is Required"),
                // matches(new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"), "Password must conatin 1-Capital 1-small 1-symbol and atleast 8 charater"),
                confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords and confirm password must match'),
            })
        ),
    });

    const onSubmit = async (data) => {
        if (mobile || email) {
            const response = mobile ? await changePasswordMobile({ mobileNumber: mobile, password: data.password }) : await changePasswordEmail({ email: email, password: data.password });
            if (response?.data?.status && response?.data?.message === "Password Changed Successfully") {
                setAlertMsg({ type: "success", message: response?.data?.message });
                setTimeout(handleHideOTPRedirect, 500)
            } else {
                setAlertMsg({ type: "error", message: response?.data?.message })
            }
        }
    }

    return (
        <Modal
            type="withoutCloseIcon3"
            className={`${className}`}
            handleModalStateChange={handleModalStateChange}
            isModalOpen={isModalOpen}
            isShowCloseBtn={true}
            handlerModalClose={handleHideOTPRedirect}
        >
            <form onSubmit={handleSubmit(onSubmit)}>

                {alertMsg?.message && <Alert type={alertMsg.type} effect='slideDown' resetAlertCallback={() => setAlertMsg(null)}>{alertMsg.message}</Alert>}

                <Heading type="h3" >Change Password</Heading>

                <div className="mt-5 xs:mt-6 sm:mt-7 lg:mt-8  mb-9 xs:mb10 sm:mb-11 lg:mb-12 mx-auto">

                    <Paragraph className="mb-8 lg:mb-12">Enter a strong password which you have not used on any other platform.</Paragraph>

                    <Input
                        type="password"
                        placeholder='Set Password*'
                        id="password"
                        register={register}
                        error={errors.password}
                        isRequiredField={true}
                        isShowPassword={isShowPassword} setIsShowPassword={setIsShowPassword}
                    />

                    <Input
                        type="password"
                        placeholder='Confirm Password*'
                        id="confirmPassword"
                        register={register}
                        error={errors.confirmPassword}
                        isRequiredField={true}
                        isShowPassword={isShowPassword} setIsShowPassword={setIsShowPassword}
                    />

                </div>

                <div className="flex flex-col items-center">
                    <Button className="w-200px" size="medium" variant="Primary" dataAutomation='save-password'>Save</Button>
                </div>

            </form>
        </Modal>
    )
}

ModalChangePassword.propTypes = {
    className: PropTypes.string,
    isModalOpen: PropTypes.bool,
    handleModalStateChange: PropTypes.func,
}

export default ModalChangePassword

