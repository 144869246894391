const NirogMoneyContext = {
    ORDER_DEBIT_NM:'ORDER_DEBIT_NM',
    SIGNUP_CREDIT_NM:'SIGNUP_CREDIT_NM',
    REFUND_CREDIT_NM:'REFUND_CREDIT_NM',
    REFERRAL_CREDIT_NM:'REFERRAL_CREDIT_NM',
    QUIZ_CREDIT_NM:'QUIZ_CREDIT_NM',
    CANCEL_CREDIT_NM:'CANCEL_CREDIT_NM',
    ADMIN_NM:'ADMIN_NM',
    ORDER_CREDIT_NM:'ORDER_CREDIT_NM',
    FIRST_POST_CREDIT_NM:'FIRST_POST_CREDIT_NM'
};


module.exports.NirogMoneyContext = NirogMoneyContext;