import ModalOtpVerification from './../../molecules/Modals/ModalOtpVerification';
import ModalTroubleLogin from './../../molecules/Modals/ModalTroubleLogin';
import React, { useEffect, useState } from 'react'
import ModalChangePassword from 'molecules/Modals/ModalChangePassword';
import { verifyOtpEmail, verifyOtpMobile } from 'services/user.service';

const ForgetPassword = ({ isForgatModal,setIsUserDeactivated, mobileNo, email, setMobileNo, alertMsg,
    setAlertMsg, afterChangePassword,
    setEmail, setIsForgatModal }) => {
    const [isModalTroubleLogin, setIsModalTroubleLogin] = useState(isForgatModal);
    const [isModalVerification, setIsModalVerification] = useState(false);
    const [isModalChangePassword, setIsModalChangePassword] = useState(false);
    const [optVerifedBy, setOtpVerifiedBy] = useState(false);
    const [otp, setOtp] = useState("");

    useEffect(() => {
        setIsModalTroubleLogin(isForgatModal);
    }, [isForgatModal])

    const setOtpVal = (data) => {
        setOtp(data)
    }

    const handleHideTroubleShowVerify = () => {
        setIsModalTroubleLogin(false);
        setIsModalVerification(true);
    }

    const handleHideVerifyShowOtp = () => {
        setIsModalVerification(false);
        setIsModalChangePassword(true);
    }

    const handleHideOTPRedirect = () => {
        afterChangePassword();
    }

    const onOtpVerify = async (data) => {
        const response = optVerifedBy === "mobile" ? (await verifyOtpMobile({ mobileNumber: mobileNo, otp: data })) : (await verifyOtpMobile({ email: email, otp: data }));
        if (response?.data?.message === "Successfully Verified Otp!") {
            setIsModalVerification(false);
            setIsModalChangePassword(true);
        } else {
            setAlertMsg({ message: response?.data?.message, type: 'error' });
        }
    }

    return (
        <>
            <ModalTroubleLogin
                isModalOpen={isModalTroubleLogin}
                setMobileNo={setMobileNo}
                setIsModalVerification={setIsModalVerification}
                setIsLoginType={setOtpVerifiedBy}
                setIsUserDeactivated={setIsUserDeactivated}
                setIsForgatModal={setIsForgatModal}
                setEmail={setEmail}
                otpScreenForLogin={() => { }}
                handleHideTroubleShowVerify={handleHideTroubleShowVerify}
                handlerModalClose={() => setIsForgatModal(false)}
            />
            <ModalOtpVerification
                handleHideVerifyShowOtp={handleHideVerifyShowOtp}
                isModalOpen={isModalVerification}
                loginUser={onOtpVerify}
                setOtpVal={setOtpVal}
                mobileNo={mobileNo}
                email={email}
                alertMsg={alertMsg}
                setAlertMsg={setAlertMsg}
                type="forget"
                handlerModalClose={() => { setIsModalVerification(false); setIsForgatModal(false) }}
            />
            <ModalChangePassword
                isModalOpen={isModalChangePassword}
                mobile={mobileNo}
                email={email}
                handleHideOTPRedirect={handleHideOTPRedirect}
            />
        </>
    )
}

export default ForgetPassword
