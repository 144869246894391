import React from 'react'

import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import Text from '../../atoms/Text'
import Modal from '../../atoms/Modal'

const ModalForDeactivatedUser = ({ isUserDeactivated, setIsUserDeactivated }) => {
    
    return (
        isUserDeactivated ? <Modal className='px-5 py-4 xxs:px-8.75 xxs:py-6 max-w-374px w-full'
            type="withoutCloseIcon2"
            isShowCloseBtn={false}
            isModalOpen={isUserDeactivated}
            dataAutomation='deactivated-user'
        >
            <Heading type='h3' className='text-center text-gray-900 font-semibold'>Attention!</Heading>
            <Text type='body' className="text-center text-gray-500 font-semibold mt-5 mb-8">
                Your Account has been Deactivated. Please contact us at +91-9319361976 for more details.</Text>
            <div className='flex justify-center'>
                <Button size='medium' className='px-3.5 w-full text-2xl max-w-35' onClick={()=>setIsUserDeactivated(false)} >Ok</Button>
            </div>
        </Modal> : null
    )
}

export default ModalForDeactivatedUser
