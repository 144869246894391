import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { useForm } from "react-hook-form";
import * as yup from 'yup';

import { yupValidator } from "../../lib/yup-validator";

import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import Modal from '../../atoms/Modal';
import Paragraph from '../../atoms/Paragraph';
import Input from '../../atoms/Input';
import Alert from '../../molecules/Alert';
import { sendOtpForLogin, sendOtpForLoginWithEmail } from '../../services/user.service';

const ModalTroubleLogin = ({ className, setMobileNo, setEmail, isModalOpen, setIsForgatModal, setIsUserDeactivated, setIsModalVerification, handleModalStateChange, setIsLoginType, otpScreenForLogin, handleHideTroubleShowVerify, handlerModalClose }) => {
    const [alertMsg, setAlertMsg] = useState(null);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupValidator(
            yup.object().shape({
                mobile_Email: yup.string()
                    .min(10, "Enter your 10 digit mobile number")
                    .test("isNumericString", "Mobile can only have Numbers.", async (value) => /^[0-9]{1,10}$/.test(value))
                    .required("Mobile Number is Required"),
            })
        ),
    });

    const onSubmit = async (data) => {
        let mobilePattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/
        let emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (data?.mobile_Email.match(mobilePattern)) {
            const response = await sendOtpForLogin({
                mobileNumber: data?.mobile_Email,
                country_code: "91",
                // context: 'change_number',
            });
            
            if (response?.data?.message.code === 111 && response?.data?.message.error === 'Your account has been deactivated') {
                setIsUserDeactivated(true);
                setIsForgatModal(false)
                return
            }
            if (response?.data?.message === "Successfully sent Otp!") {
                setMobileNo(data?.mobile_Email);
                setIsLoginType('mobile');
                otpScreenForLogin(true);
                setIsModalVerification(true);
            } else {
                setAlertMsg({ type: "error", message: "Please verify your mobile and try again" })
            }
        }
        // else if (data?.mobile_Email.toLowerCase().match(emailPattern)) {
        //     const response = await sendOtpForLoginWithEmail({
        //         email: data.mobile_Email
        //     })
        //     if (response?.data?.message.code === 111 && response?.data?.message.error === 'Your account has been deactivated') {
        //         setIsUserDeactivated(true);
        //         setIsForgatModal(false)
        //         return
        //     }
        //     if (response?.data?.message === "Successfully sent Otp!") {
        //         setEmail(data?.mobile_Email)
        //         otpScreenForLogin(true)
        //         setIsLoginType('email')
        //         setIsModalVerification(true);
        //     } else {
        //         setAlertMsg({ type: "error", message: "Please verify your email and try again" })
        //     }
        // }
        else {
            if (data?.mobile_Email) {
                setAlertMsg({ type: "error", message: "Invalid mobile" })
            }
        }
    }

    return (
        <Modal
            type="withoutCloseIcon3"
            className={`${className} max-w-400px xs:max-w-460px sm:max-w-500px lg:max-w-612px w-full`}
            handleModalStateChange={handleModalStateChange}
            isModalOpen={isModalOpen}
            isShowCloseBtn={true}
            handlerModalClose={handlerModalClose}
            dataAutomation='trouble-with-login'
        >
            {alertMsg?.message && (
                <Alert type={alertMsg.type} effect="slideDown" resetAlertCallback={() => setAlertMsg(null)}>
                    {alertMsg.message}
                </Alert>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Heading type="h3" className='text-center'>Trouble with login?</Heading>

                <div className="mt-5 xs:mt-6 sm:mt-7 lg:mt-8  mb-9 xs:mb10 sm:mb-11 lg:mb-12 mx-auto">

                    <Paragraph className="mb-8 lg:mb-12 text-center">Enter your mobile number and we’ll send you OTP to change password</Paragraph>

                    <Input
                        type="number"
                        placeholder='Enter mobile number'
                        register={register}
                        id="mobile_Email"
                        error={errors.mobile_Email}
                        isRequiredField={true}
                        maxLength={10}
                        setValue={setValue}
                    />
                </div>

                <div className="flex flex-col items-center">
                    <Button
                        className="w-200px"
                        size="medium"
                        variant="Primary"
                        dataAutomation='get-trouble-otp'
                    >
                        Get OTP
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

ModalTroubleLogin.propTypes = {
    className: PropTypes.string,
    isModalOpen: PropTypes.bool,
    handleModalStateChange: PropTypes.func,
}

export default ModalTroubleLogin

