import Tab from 'atoms/Tab'
import TabsSlide from 'atoms/v1/TabsSlide'
import { LOGIN_STATUS } from 'constant/login_signup'
import React from 'react'

const SlideTab = ({ className = '', activeTab, setActiveTab, individualTabName, practitionerTabName }) => {
    return (
        <div className={`${className} w-full flex justify-center px-4`}>
            <TabsSlide className="bg-transparent-0" tabsClass='' defaultActive={ activeTab === LOGIN_STATUS.INDIVIDUAL ?  individualTabName : practitionerTabName}>
                <Tab name={individualTabName} id={LOGIN_STATUS.INDIVIDUAL} onClick={() => setActiveTab(LOGIN_STATUS.INDIVIDUAL)}>{''}</Tab>
                <Tab name={practitionerTabName} id={LOGIN_STATUS.PRACTITIONER} onClick={() => setActiveTab(LOGIN_STATUS.PRACTITIONER)}>{''}</Tab>
            </TabsSlide>
        </div>
    )
}

export default SlideTab