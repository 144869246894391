import * as HttpService from "./http.service";
import { ADD_NIROG_MONEY, GET_ALL_NIROGMONEY_BY_USER_ID, GET_NIROG_MONEY_TERMSCONDITIONS } from "./url.service";



export const getNirogMoneyTermsConditions = (data) => {
    return HttpService.postWithAuth(GET_NIROG_MONEY_TERMSCONDITIONS(), data);
};

export const addNirogMoney = (data) => {
    return HttpService.postWithAuth(ADD_NIROG_MONEY(), data);
};

export const getAllNirogMoneyByUserId = (userId, search, pageNo = 1, pageSize = 10) => {
    return HttpService.getWithAuth(GET_ALL_NIROGMONEY_BY_USER_ID({ userId, search, pageNo, pageSize }));
}
