import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loading';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import Modal from '../../atoms/Modal';
import OTPInput from '../../atoms/OTPInput';
import Paragraph from '../../atoms/Paragraph';
import Alert from './../../molecules/Alert';
import { sendOtp, sendOtpForLogin, sendOtpForLoginWithEmail, sendOtpUpdate } from './../../services/user.service';
import { Context } from '../../constant/_context'
import * as moengage from "services/moengage.service";
import mo_engage_onboarding from "../../assets/mo_engage/onboarding";
import { OnClickProfileMobileNumberEditProceed, OnClickProfileMobileNumberEditResendOTP, OnClickProfileMobileNumberEditChangeNumber } from "../../components/ProfileCard/ProfileMoengage";
import { useRouter } from "next/router";
import { getAuth } from 'services/identity.service';

const ModalOtpVerification = ({ className, isModalOpen, handleModalStateChange, apiData, creatUser, mobileNo, email, type, loginUser, handleLoginScreen,
    alertMsg, setAlertMsg, handlerModalClose, changeLabel, isLoading, otpCallback = () => { } }) => {
    const [otpValue, setOtpValue] = useState("");
    const [isDisableProceedBtn, setIsDisableProceedBtn] = useState(true);
    const entity = mo_engage_onboarding.entity;
    const router = useRouter();
    const auth = getAuth();
    const [countdown, setCountdown] = useState(30);
    const [isResendDisabled, setIsResendDisabled] = useState(true);

    useEffect(() => {
        let timer;
        if (isResendDisabled) {
            timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev === 1) {
                        clearInterval(timer);
                        setIsResendDisabled(false);
                        return 30;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isResendDisabled]);

    useEffect(() => {
        if (otpValue.length == 4) {
            otpCallback(otpValue)
        }
    }, [otpValue])


    const hideEmail = (email) => {
        var hiddenEmail = "";
        for (let i = 0; i < email.length; i++) {
            if (i > 2 && i < email.indexOf("@")) {
                hiddenEmail += "*";
            } else {
                hiddenEmail += email[i];
            }
        }
        return hiddenEmail
    }
    const hidePhoneNumber = (mobile) => {
        if (mobile) return mobile.replace(mobile.substring(0, 6), 'XXXXXX');
    }
    const backToRegScreen = () => {
        moengage.trackEvent(entity.onboarding_change_number_signup.event_name, {})
        setOtpValue("")
        handleModalStateChange(false)
    }


    const handleResendOtp = async () => {
        setIsResendDisabled(true);
        moengage.trackEvent(entity.onboarding_resend_OTP_signup.event_name, {})
        let mobilePattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/
        let emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (type == 'login') {
            if (mobileNo.match(mobilePattern)) {
                const response = await sendOtpForLogin({
                    mobileNumber: mobileNo,
                    country_code: "91",
                    context: Context.account_login_otp,
                    userType: router.query.type == 'individual' ? 1 : null,
                })
                if (response?.data?.message === "Successfully sent Otp!") {
                    setAlertMsg({ message: "Resent OTP successfully!", type: "success" });
                } else {
                    setAlertMsg({ type: "error", message: "Please verify your mobile and try again" })
                }
            } else if (email.toLowerCase().match(emailPattern)) {
                const response = await sendOtpForLoginWithEmail({
                    email: email
                })
                if (response?.data?.message === "Successfully sent Otp!") {
                    setAlertMsg({ message: "Resent OTP successfully!", type: "success" });
                } else {
                    setAlertMsg({ type: "error", message: "Please verify your email and try again" })
                }
            }
        }
        else if (type == 'reg') {
            const response = await sendOtp({
                mobileNumber: apiData?.mobileNumber,
                country_code: apiData?.country_code,
                email: apiData?.userData?.email,
                full_name: apiData?.userData?.full_name,
                userType: apiData.userData.userType
            });
            if (response?.data?.message === "Successfully sent Otp!") {
                setAlertMsg({ message: "Resent OTP successfully!", type: "success" });
            } else {
                setAlertMsg({ message: response?.data?.message, type: "error" });
            }
        }
        else {
            const response = await sendOtpUpdate({
                toUpdate: mobileNo,
                email: email,
                userId: auth?.user?.id,
            });
            if (response?.status) {
                setAlertMsg({ message: "Resent OTP successfully!", type: "success" });
            } else {
                setAlertMsg({ message: response?.message, type: "error" });
            }
        }
    }

    useEffect(() => {
        if (otpValue.length === 4) {
            setIsDisableProceedBtn(false)
        } else {
            setIsDisableProceedBtn(true)
        }
    }, [otpValue.length])


    return (
        isModalOpen ? <Modal
            type="withoutCloseIcon2"
            className={`${className} relative`}
            isModalOpen={isModalOpen}
            isShowCloseBtn={true}
            handlerModalClose={() => { handlerModalClose(); setOtpValue('') }}
            dataAutomation='verification-otp'
        >
            {alertMsg?.message && <Alert type={alertMsg.type} effect='slideDown' resetAlertCallback={() => setAlertMsg(null)}>{alertMsg.message}</Alert>}
            <Heading type="h3" className='text-center'>Verification</Heading>

            <div className="py-6 xs:py-10 sm:py-12 lg:py-8 mx-auto">

                <Paragraph className="mb-8 lg:mb-12 text-center">
                    {/* We have sent a 4 digit OTP via SMS and WhatsApp on  {mobileNo && "+91 " + hidePhoneNumber(mobileNo)} {mobileNo && email && "and email on"} {email && ` ${hideEmail(email)}`} */}
                    We have sent a 4 digit OTP via SMS and WhatsApp on  {mobileNo && "+91 " + hidePhoneNumber(mobileNo)}
                </Paragraph>
                {isLoading && <Loader />}

                <OTPInput
                    className="flex justify-center"
                    length={4}
                    seperator={<span>-</span>}
                    onChange={(otp) => { setOtpValue(otp) }}
                    otpValue={otpValue}
                />
                <div className={`flex mt-5 ${type === 'forget' ? 'justify-center' : 'justify-between'}`}>
                    <Button variant="Link" size="medium" fontSize='overline' onClick={() => { setOtpValue(''); handleResendOtp(); }} dataAutomation='resend-otp' disabled={isResendDisabled}
                        className={isResendDisabled ? "!bg-basic-whitePure border-none" : ''}
                    >
                        {isResendDisabled ? `Re-send OTP in ${countdown}s` : 'Re-send OTP'}
                    </Button>
                    {type !== 'forget' && type !== 'reg' && <Button variant="Link" fontSize='overline' size="medium" onClick={() => OnClickProfileMobileNumberEditChangeNumber(() => { type === "reg" ? backToRegScreen() : handleLoginScreen(true); setOtpValue('') })} dataAutomation={changeLabel ? `change-${changeLabel.toLowerCase()}` : "change-number"}>Change {changeLabel ? changeLabel : "Number"}</Button>}
                </div>
            </div>

            <div className="flex justify-center">
                <Button
                    className="w-200px"
                    size="medium"
                    variant="Primary"
                    onClick={() => OnClickProfileMobileNumberEditProceed(() => {
                        setIsDisableProceedBtn(true);
                        setOtpValue('');
                        type === "reg" ? creatUser(otpValue) : loginUser(otpValue);
                    })}
                    disabled={isDisableProceedBtn}
                    dataAutomation='otp-proceed'
                >
                    Proceed
                </Button>
            </div>
        </Modal> : null
    )
}

ModalOtpVerification.propTypes = {
    className: PropTypes.string,
    isModalOpen: PropTypes.bool,
    handleModalStateChange: PropTypes.func,
    creatUser: PropTypes.func,
    mobileNo: PropTypes.string,
    email: PropTypes.string,
}

export default ModalOtpVerification
