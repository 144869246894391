import React, { useEffect, useState } from "react";
import Heading from "atoms/Heading";

const SignUpLeftSide = ({ }) => {
    let timeoutVar;

    const [index, setIndex] = useState(1);

    const selected = (index) => {
        setIndex(index);
        clearTimeout(timeoutVar);
    };
    const subHeading = {
        1: "Buy Medicines",
        2: "Community Building",
        3: "Expert Consultation",
    };

    useEffect(() => {
        timeoutVar = setTimeout(() => {
            const current = index <= Object.keys(subHeading).length - 1 ? index + 1 : 1;
            selected(current);
        }, 3500);
    }, [index]);

    return (
        <div className="w-full ml-[0px] 2xl:ml-[-30px] hidden md:flex">
            <div className="sticky  left-0 top-40 hidden md:flex w-full bg-light-gray text-center delay-500 flex-col">
                <Heading type="h1" className="mt-8 font-Inter text-2xl xl:text-3xl" textColor="text-gray-900">
                    {subHeading[index]}
                </Heading>
                <div className="flex mt-2">
                    <div className="w-full px-2 m-auto h-[calc((100vh_-_400px))] min-h-[100px] max-h-[420px] overflow-hidden">
                        <img src={`images/banner${index}.svg`} alt="banner" style={{ width: "100%", height: "100%" }} />
                    </div>
                </div>
                <div className="min-h-[20px] max-h-[100px] h-[calc((100vh_-_740px)_-_10vh)] cursor-pointer flex justify-center items-center  mt-0">
                    <div className="flex">
                        <div onClick={() => selected(1)} className={`${index === 1 ? "bg-primary1-500" : "bg-gray-400"} m-1 w-${index === 1 ? '4.5' : '2.5'} h-2.5 rounded-full`}></div>
                        <div onClick={() => selected(2)} className={`${index === 2 ? "bg-primary1-500" : "bg-gray-400"} m-1 w-${index === 2 ? '4.5' : '2.5'} h-2.5 rounded-full`}></div>
                        <div onClick={() => selected(3)} className={`${index === 3 ? "bg-primary1-500" : "bg-gray-400"} m-1 w-${index === 3 ? '4.5' : '2.5'} h-2.5 rounded-full`}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpLeftSide;
