import { trackEvent } from "services/moengage.service";
import login_signup_moengage from "../../assets/mo_engage/login_signup.json";
import { captureEvent } from "services/analytics.service";

const events = login_signup_moengage.entity;
const category = login_signup_moengage.category;

export const gaLoginPageGetOTP = (attributes = {}) => {
    captureEvent(category, events.Login_Page_Get_OTP.event_name, attributes)
}

export const gaEngageLoginPageSubmitOTP = (attributes = {}) => {
    captureEvent(category, events.Login_Page_Submit_OTP.event_name, attributes)
}


export const gaEngageLoginPageResendOTP = (attributes = {}) => {
    captureEvent(category, events.Login_Page_Resend_OTP.event_name, attributes)
}


export const gaEngageLoginPageChangeNumber = (attributes = {}) => {
    captureEvent(category, events.Login_Page_Change_Number.event_name, attributes)
}

export const gaEngageLoginPageLoginUsingOTP = (attributes = {}) => {
    captureEvent(category, events.Login_Page_Login_Using_OTP.event_name, attributes)
}

export const gaEngageLoginPageLoginUsingPassword = (attributes = {}) => {
    captureEvent(category, events.Login_Page_Login_Using_Password.event_name, attributes)
}

export const gaEngageLoginPageSubmitPassword = (attributes = {}) => {
    captureEvent(category, events.Login_Page_Submit_Password.event_name, attributes)
}

export const gaEngageLoginPageSignUpClick = (attributes = {}) => {
    captureEvent(category, events.Login_Page_Sign_Up_Click.event_name, attributes)
}

export const gaEngageAccountRegistrationSubmitGetOTP = (attributes = {}) => {
    captureEvent(category, events.Account_Registration_Get_OTP.event_name, attributes)
}

export const gaEngageSignupPageLoginClick = (attributes = {}) => {
    captureEvent(category, events.Signup_Page_Login_Click.event_name, attributes)
}

export const gaIndividualLoginGetOtpClick = (attributes = {}) => {
    captureEvent(category, events.Individual_Login_Get_OTP_Click.event_name, attributes)
}

export const gaIndividualRegisterSubmitOtpClick = (attributes = {}) => {
    captureEvent(category, events.Individual_Account_Registration_Get_OTP.event_name, attributes)
}

